export const event = (el, event, func) => {
    let buttons = document.querySelectorAll(el),
        index, button;

    for (index = 0; index < buttons.length; index++) {
        button = buttons[index];
        button.removeEventListener(event, func);
        button.addEventListener(event, func);
    }
};

export const serialize = (form) => {
    'use strict';
    let i, j, len, jLen, formElement, q = [];
    function urlencode (str) {
        // http://kevin.vanzonneveld.net
        // Tilde should be allowed unescaped in future versions of PHP (as reflected below), but if you want to reflect current
        // PHP behavior, you would need to add ".replace(/~/g, '%7E');" to the following.
        return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').
        replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');
    }
    function addNameValue(name, value) {
        q.push(urlencode(name) + '=' + urlencode(value));
    }
    if (!form || !form.nodeName || form.nodeName.toLowerCase() !== 'form') {
        throw 'You must supply a form element';
    }
    for (i = 0, len = form.elements.length; i < len; i++) {
        formElement = form.elements[i];
        if (formElement.name === '' || formElement.disabled) {
            continue;
        }
        switch (formElement.nodeName.toLowerCase()) {
            case 'input':
                switch (formElement.type) {
                    case 'text':
                    case 'tel':
                    case 'email':
                    case 'hidden':
                    case 'password':
                    case 'button': // Not submitted when submitting form manually, though jQuery does serialize this and it can be an HTML4 successful control
                    case 'submit':
                        addNameValue(formElement.name, formElement.value);
                        break;
                    case 'checkbox':
                    case 'radio':
                        if (formElement.checked) {
                            addNameValue(formElement.name, formElement.value);
                        }
                        break;
                    case 'file':
                        // addNameValue(formElement.name, formElement.value); // Will work and part of HTML4 "successful controls", but not used in jQuery
                        break;
                    case 'reset':
                        break;
                }
                break;
            case 'textarea':
                addNameValue(formElement.name, formElement.value);
                break;
            case 'select':
                switch (formElement.type) {
                    case 'select-one':
                        addNameValue(formElement.name, formElement.value);
                        break;
                    case 'select-multiple':
                        for (j = 0, jLen = formElement.options.length; j < jLen; j++) {
                            if (formElement.options[j].selected) {
                                addNameValue(formElement.name, formElement.options[j].value);
                            }
                        }
                        break;
                }
                break;
            case 'button': // jQuery does not submit these, though it is an HTML4 successful control
                switch (formElement.type) {
                    case 'reset':
                    case 'submit':
                    case 'button':
                        addNameValue(formElement.name, formElement.value);
                        break;
                }
                break;
        }
    }
    return q.join('&');
}